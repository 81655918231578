:root {
  --primary-color: #ccd7cf;
  --secondary-color: #8eb19a;
  --tertiary-color: #a4bfad;
}

.App {
  position: relative;
  overflow: hidden;
  font-family: "Poppins", sans-serif !important;
}

a {
  color: inherit;
}

body::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: #f1b398; /* color of the scroll thumb */
  border-radius: 16px; /* roundness of the scroll thumb */
  border: 3px solid #f1b398; /* creates padding around scroll thumb */
}

.headerContainer {
  padding-top: 0.9em;
}

.headerAppBar {
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: none !important;
  color: var(--primary-color);
}

.headerLottie {
  width: 25%;
}

.title {
  font-weight: 900 !important;
  letter-spacing: 1px !important;
  font-size: 40px !important;
  font-family: "Poppins", sans-serif !important;
  color: #f1b398;
}

.contactButton {
  font-size: 25px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  color: white !important;
  text-transform: lowercase !important;
  letter-spacing: 1px !important;
  /* padding-right: 2em !important; */
}

.contactButtonIcon {
  color: rgb(142, 177, 154) !important;
}

.fFRNGy {
  background-color: var(--primary-color);
  position: absolute;
  right: 0px;
  border-radius: 76% 0px 0px 0%;
  height: 75rem;
  margin-top: 0px;
  width: 116%;
  z-index: -1;
}

.introBox {
  padding: 6rem 2rem;
}

.introText {
  font-size: 2.25rem !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 1.3;
  letter-spacing: -0.016em;
  color: white;
  position: relative;
}

.intro_curve_divider {
  display: block;
  width: 100%;
  margin-bottom: -0.25rem;
  color: var(--secondary-color);
  z-index: 1;
}

.aboutMeContainer {
  font-size: 2.25rem;
  line-height: 1.3;
  letter-spacing: -0.016em;
  position: relative;
  background-color: var(--secondary-color);
  color: white;
  padding-top: 1.75em;
}

.aboutMe_Heading {
  font-size: 2.8rem !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  position: relative;
  z-index: 2;
}

.container {
  position: relative;
  margin-bottom: 2em;
  max-width: 52rem;
  z-index: 3;

}

.svgBlob {
  position: absolute;
  z-index: 1;
  color: var(--tertiary-color);
  width: 70%;
}

.content {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;

  position: relative;
  z-index: 2;
  color: white;
}

.footerContainer {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: var(--primary-color);
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  color: white;
  position: relative;
  z-index: 2;
}

.iconHover :hover {
  color: #f2f2f2;
}

.lottieGuyTyping {
  width: 90%;
}

.lottieTraveling {
  width: 80%;
  margin: auto;
}

/* ==========Contact Me========== */


.ContactMePageContainer {
  background-color: transparent;
   height: 100vh;
   max-width: 100vw !important;
   background-color: var(--primary-color);

}

.ContactMePageContainer .headerContainer {
  background-color: var(--primary-color);
}

.contactMeBox {
  height: 77vh;
}

.contactMeText {
  position: relative;
  z-index: 3;
  width: 100%;
  /* margin: auto !important; */
  padding: 0;
  margin-left: 1em !important;
}

/* .jJTUTo .topLeftBackgroundBlob {
  color: blue;
  position: absolute;
  z-index: 2;
} */

.topLeftBackgroundBlob {
  color: white;
  position: absolute;
  max-width: 55%;
  left: 0px;
}



/* .jJTUTo .sc-1aviv36-2 {
  color: rgb(168, 198, 178);
} */

/* .jEajig {
  padding: 5rem 1.25rem 3.75rem;
} */

.contactMeBackgroundBlob {
  /* display: block; */
  margin: auto;
  position: relative;
  z-index: 3;
  max-width: 60% !important;
  /* padding-top: 2%; */
  color: rgb(168, 198, 178);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

/* .contactMeBackgroundBlob {
  max-width: 90%;
} */

/* .ContactMePageContainer .headerAppBar {
  color: var(--primary-color);
  background-color: var(--primary-color);
 } */

@media (min-width: 960px) {
  .fFRNGy {
    border-radius: 38% 0px 0px 53%;
    height: 625rem;
    margin-top: -5.9375rem;
    width: 80%;
  }
}

@media (max-width: 960px) {
  .svgBlob {
    z-index: -1;
  }
}

@media (max-width: 640px) {
  .title {
    padding-left: 0.75em;
    font-weight: 900 !important;
    letter-spacing: 1px !important;
    font-size: 20px !important;
  }

  .headerLottie {
    width: 40%;
  }

  .contactButton {
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;

    color: white !important;
    text-transform: lowercase !important;
    letter-spacing: 1px !important;
    padding-right: 0.75em !important;
  }

  .lottieGuyTyping {
    padding-bottom: 2em;
  }

  .headerAppBar {
    padding-left: 0em;
    padding-right: 0em;
  }

  .introText {
    font-size: 1.6rem !important;
    position: relative;
    align-items: center !important;
    justify-content: center !important;
    padding: 0;
    margin: auto;
  }

  .introBox {
    padding: 2.5rem 1rem;
  }

  .aboutMeContainer {
    padding-top: 1em;
  }

  .aboutMe_Heading {
    font-size: 2rem !important;
  }

  .content {
    font-size: 1rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .intro_curve_divider {
    width: 115%;
  }

  .lottieTraveling {
    width: 50%;
    margin: auto;
  }
}
