* {
  margin: 0;
  padding: 0;
}

a {
  color: blue;
  text-decoration: none; /* no underline */
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

/* #error-page h1, p {
  padding: 1em;
} */

@media (min-width: 640px) {
  .hAxFlT {
    max-width: 50%;
  }
}
